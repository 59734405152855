export default function About({ appName, aboutTranslation }: { appName: string; aboutTranslation: any }) {
  return (
    <section
      id={`about`}
      className={`flex flex-col items-center justify-center bg-wkm-about-bg-mobile bg-cover bg-center bg-no-repeat pb-[10px] pt-[40px] text-[#050C29] lg:bg-wkm-about-bg lg:py-[150px]`}
    >
      <div
        className={`pt-[20px] text-center text-[20px] font-extrabold leading-[75px] sm:text-[32px] lg:pt-[50px] lg:text-[50px]`}
      >
        {aboutTranslation.title}
      </div>
      <div
        className={`mt-[40px] max-w-[1300px] px-[30px] pb-[50px] text-center text-[15px] font-semibold leading-[25px] sm:mt-[30px] sm:text-[15px] lg:mt-[70px] lg:pb-[70px] lg:text-[25px] lg:leading-[38px]`}
      >
        {aboutTranslation.description}
      </div>
    </section>
  )
}
