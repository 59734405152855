import { motion } from 'framer-motion'

export default function Explorer({
  title,
  description,
  btnExplorerText,
}: {
  title: string
  description: any
  btnExplorerText: string
}) {
  return (
    <section className={`relative mt-[70px] flex flex-col items-center lg:mt-[275px]`}>
      <div className={`mx-auto flex flex-col justify-center`}>
        <h2 className={`z-20 text-center text-[25px] font-extrabold leading-[25px] lg:text-[70px] lg:leading-[70px]`}>
          {title}
        </h2>
        <div
          className={`z-20 w-full max-w-[500px] px-6 pt-[65px] text-center text-[13px] font-semibold leading-[20px] -tracking-[2%] lg:max-w-[1100px] lg:px-0 lg:text-[25px] lg:leading-[38px]`}
        >
          {description}
        </div>
      </div>
      <img
        src={`/assets/images/wkm_explorer_bg.png`}
        alt={`WalkMining Explorer`}
        className={`-mb-[200px] -mt-[250px] hidden w-full lg:block`}
      />
      <img
        src={`/assets/images/wkm_explorer_bg.png`}
        alt={`WalkMining Explorer`}
        className={`my-0 block w-full md:-my-[100px] lg:hidden`}
      />
      <motion.a
        whileHover={{ scale: 1.1 }}
        transition={{ type: 'spring', stiffness: 1000, damping: 10 }}
        className={`button-frame z-20 mx-6 mb-[150px] rounded-[100px] px-[30px] py-[16px] text-[18px] font-semibold leading-[25px] -tracking-[2%] lg:mx-0 lg:mb-[250px] lg:px-[75px] lg:py-[35px] lg:text-[25px]`}
        href={`https://bit.ly/3WuxcBA`}
        target={`_blank`}
      >
        {btnExplorerText}
      </motion.a>
    </section>
  )
}
