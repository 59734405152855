import { motion } from 'framer-motion'

export default function ProductFeature({
  gameTitle,
  shopTitle,
  gameItems,
  shopItems,
}: {
  gameTitle: any
  shopTitle: any
  gameItems: any[]
  shopItems: any[]
}) {
  const FeatureGames = () => {
    return (
      <>
        <div className={`flex w-full flex-col items-center justify-center space-y-[250px]`}>
          <div className={`flex w-full flex-col items-center justify-center`}>
            <h2
              className={`hidden max-w-[300px] text-center text-[25px] font-black leading-[33px] -tracking-[0.03em] lg:block lg:max-w-[746px] lg:text-left lg:text-[60px] lg:leading-[78px]`}
            >
              {gameTitle}
            </h2>
            <h2
              className={`block max-w-[350px] text-center text-[25px] font-black leading-[33px] -tracking-[0.03em] lg:hidden lg:max-w-[746px] lg:text-left lg:text-[60px] lg:leading-[78px]`}
            >
              {gameTitle}
            </h2>
            <motion.img
              animate={{
                y: [-15, 0, -15],
              }}
              transition={{
                repeat: Infinity,
                duration: 4,
                repeatType: 'loop',
              }}
              src={`/assets/images/app_games.png`}
              alt={gameTitle}
              className={`transition-opacity} rounded-[20px]`}
            />
            {/* <img
              src={`/assets/images/app_games.png`}
              alt={'app_game'}
              className={`h-auto min-w-[550px] overflow-hidden lg:-mt-[10px] lg:w-[1100px]`}
            /> */}
            <div
              className={`flex flex-col items-start space-y-[15px] px-[20px] lg:-mt-[120px] lg:w-[60%] lg:space-y-[30px]`}
            >
              <div className={`max-w-[300px] lg:max-w-[465px]`}>
                <h3 className={`text-[18px] font-extrabold leading-[38px] lg:text-[35px]`}>{gameItems[0].title}</h3>
                <div
                  className={`text-[13px] font-semibold leading-[20px] lg:pt-[20px] lg:text-[20px] lg:leading-[30px]`}
                >
                  {gameItems[0].description}
                </div>
              </div>
              <div>
                <h3 className={`text-[18px] font-extrabold leading-[38px] lg:text-[35px]`}>{gameItems[1].title}</h3>
                <div
                  className={`text-[13px] font-semibold leading-[20px] lg:pt-[20px] lg:text-[20px] lg:leading-[30px]`}
                >
                  {gameItems[1].description}
                </div>
              </div>
              <div>
                <h3 className={`text-[18px] font-extrabold leading-[38px] lg:text-[35px]`}>{gameItems[2].title}</h3>
                <div
                  className={`text-[13px] font-semibold leading-[20px] lg:pt-[20px] lg:text-[20px] lg:leading-[30px]`}
                >
                  {gameItems[2].description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const FeatureShop = () => {
    return (
      <>
        <div className={`flex w-full flex-col items-center justify-center space-y-[250px]`}>
          <div className={`flex w-full flex-col items-center justify-center`}>
            <h2
              className={`hidden max-w-[300px] text-center text-[25px] font-black leading-[33px] -tracking-[0.03em] lg:block lg:max-w-[700px] lg:text-left lg:text-[60px] lg:leading-[78px]`}
            >
              {shopTitle}
            </h2>
            <h2
              className={`block max-w-[300px] text-center text-[25px] font-black leading-[33px] -tracking-[0.03em] lg:hidden lg:max-w-[746px] lg:text-left lg:text-[60px] lg:leading-[78px]`}
            >
              {shopTitle}
            </h2>
            <motion.img
              animate={{
                y: [-15, 0, -15],
              }}
              transition={{
                repeat: Infinity,
                duration: 4,
                repeatType: 'loop',
              }}
              src={`/assets/images/reward_shop.png`}
              alt={gameTitle}
              className={`transition-opacity} rounded-[20px]`}
            />
            {/* <img
              src={`/assets/images/reward_shop.png`}
              alt={'reward_shop'}
              className={`h-auto min-w-[550px] overflow-hidden lg:-mt-[10px] lg:w-[1100px]`}
            /> */}
            <div
              className={`flex flex-col items-start space-y-[15px] px-[20px] lg:-mt-[120px] lg:w-[60%] lg:space-y-[30px]`}
            >
              <div className={`max-w-[465px]`}>
                <h3 className={`text-[18px] font-extrabold leading-[38px] lg:text-[35px]`}>{shopItems[0].title}</h3>
                <div
                  className={`text-[13px] font-semibold leading-[20px] -tracking-[0.02em] lg:pt-[20px] lg:text-[20px] lg:leading-[30px]`}
                >
                  {shopItems[0].description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <section
        id={`features`}
        className={`relative z-50 flex flex-col items-center justify-center bg-future-bg bg-cover pb-[100px] text-[#050C29] 2xl:pb-[100px] min-[2000px]:pb-[300px]`}
      >
        <FeatureGames />
      </section>
      <section
        id={`features`}
        className={`bg-future-bg2 relative z-50 flex flex-col items-center justify-center bg-cover pb-[100px] text-[#050C29] lg:pt-[230px] 2xl:pb-[100px] min-[2000px]:pb-[300px]`}
      >
        <FeatureShop />
      </section>
    </>
  )
}
