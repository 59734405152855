import Link from 'next/link'
import { CommunityLinkData } from '../../util/types/CommonType'

export default function Footer({
  noticeTitle,
  aboutTitle,
  terms,
  privacy,
  communities,
}: {
  noticeTitle: any
  communities: CommunityLinkData[] | []
  aboutTitle: any
  terms: any
  privacy: any
}) {
  const year = new Date().getFullYear()
  const imageMap = new Map([
    ['TWITTER', `/assets/images/twitter.svg`],
    ['DISCORD', `/assets/images/discord.svg`],
    ['TELEGRAM', `/assets/images/telegram.svg`],
    ['GITBOOK', `/assets/images/gitbook.svg`],
    ['INSTAGRAM', `/assets/images/instagram.svg`],
    ['MEDIUM', `/assets/images/medium.svg`],
  ])
  const generateCommunityIcon = (community: CommunityLinkData) => {
    const imageSource = imageMap.get(community.id)
    return (
      <a href={community.url} target={'_blank'} rel={`noopener noreferrer`}>
        <img src={imageSource} alt={community.name} width={'32'} height={'32'} />
      </a>
    )
  }

  return (
    <footer className="relative z-50 -mt-[180px] h-[180px] text-[#050C29]">
      <div className={`z-50 flex h-[180px] flex-col`}>
        <div className="flex h-full items-center justify-center text-center text-[14px] font-light leading-[28px] lg:text-[16px] xl:text-[18px]">
          © {year} WalkMining Inc. All rights reserved.
        </div>
        {communities.length > 0 && (
          <div className={`mt-4 flex items-center justify-center gap-6`}>
            {communities.map((community, idx) => {
              return <div key={idx}>{generateCommunityIcon(community)}</div>
            })}
          </div>
        )}
        <div className="flex h-full items-center justify-center py-4 pr-4 text-center text-[14px] font-light leading-[14px] md:pt-0 xl:text-[14px]">
          <div className={`mt-4 flex items-center justify-center gap-4`}>
            <a href="https://docs.walkmining.com" target={'_blank'} rel={`noopener noreferrer`}>
              {aboutTitle}
            </a>
            <Link href="/notice?ref=WEB">{noticeTitle}</Link>
            <Link href="/terms-of-service">{terms}</Link>
            <Link href="/privacy">{privacy}</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
