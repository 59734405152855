import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'

export default function Hero({ appName, heroTranslation, playStoreUrl }: any) {
  const [userDevice, setUserDevice] = useState('DESKTOP')

  useEffect(() => {
    if (isAndroid) {
      setUserDevice('ANDROID')
    } else if (isIOS) {
      setUserDevice('IOS')
    } else {
      setUserDevice('DESKTOP')
    }
  }, [isAndroid, isDesktop, isIOS])

  return (
    <section
      id={`hero`}
      className={`relative -mt-[70px] flex flex-col bg-hero-bg-mobile bg-cover md:bg-hero-bg lg:-mt-[100px]`}
    >
      <div
        className={`flex flex-col-reverse items-center justify-center pb-[150px] pt-[150px] xl:flex-row xl:gap-x-[20px] xl:px-[40px] xl:pb-[200px] xl:pt-[270px] min-[2000px]:pb-[400px] min-[2000px]:pt-[400px]`}
      >
        <div className={`mt-[70px] flex max-w-[650px] select-none flex-col px-6 xl:mt-0 xl:px-0`}>
          <h1
            className={`text-center text-[30px] font-bold leading-[35px] xl:text-left xl:text-[55px] xl:leading-[70px]`}
          >
            {heroTranslation.title}
          </h1>
          {userDevice === 'DESKTOP' && (
            <div className={`flex justify-center pt-[20px] xl:justify-start xl:pt-[65px]`}>
              <a href={playStoreUrl} target={`_blank`} rel="noopener noreferrer">
                <img
                  src={`/assets/images/app-store/google_play.png`}
                  alt={`google`}
                  className={`max-w-[155px] lg:max-w-[246px]`}
                />
              </a>
              <a href={`https://apps.apple.com/app/id6443983559`} target={`_blank`} rel="noopener noreferrer">
                <img
                  src={`/assets/images/app-store/apple_store.png`}
                  alt={`apple`}
                  className={`max-w-[155px] lg:max-w-[246px]`}
                />
              </a>
            </div>
          )}
          {userDevice === 'ANDROID' && (
            <div className={`flex justify-center pt-[20px] xl:justify-start xl:pt-[65px]`}>
              <a href={playStoreUrl} target={`_blank`} rel="noopener noreferrer">
                <img
                  src={`/assets/images/app-store/google_play.png`}
                  alt={`google`}
                  className={`max-w-[155px] lg:max-w-[246px]`}
                />
              </a>
              <a href={`https://bit.ly/3WuxcBA`} target={`_blank`} rel="noopener noreferrer">
                <img
                  src={`/assets/images/app-store/wkm_explorer.png`}
                  alt={`google`}
                  className={`max-w-[155px] lg:max-w-[246px]`}
                />
              </a>
            </div>
          )}
          {userDevice === 'IOS' && (
            <div className={`flex justify-center pt-[20px] xl:justify-start xl:pt-[65px]`}>
              <a href={`https://apps.apple.com/app/id6443983559`} target={`_blank`} rel="noopener noreferrer">
                <img
                  src={`/assets/images/app-store/apple_store.png`}
                  alt={`apple`}
                  className={`max-w-[155px] lg:max-w-[246px]`}
                />
              </a>
              <a href={`https://bit.ly/3WuxcBA`} target={`_blank`} rel="noopener noreferrer">
                <img
                  src={`/assets/images/app-store/wkm_explorer.png`}
                  alt={`google`}
                  className={`max-w-[155px] lg:max-w-[246px]`}
                />
              </a>
            </div>
          )}
        </div>
        <div>
          <motion.img
            animate={{
              y: [-15, 0, -15],
            }}
            transition={{
              repeat: Infinity,
              duration: 4,
              repeatType: 'loop',
            }}
            className={`min-w-[295px] px-6 md:min-w-[450px] md:px-0`}
            src={`/assets/images/app_screens.png`}
            alt={appName}
          />
        </div>
      </div>
    </section>
  )
}
