export default function TotalWalks({ description, totalStep }: { description: any; totalStep: any }) {
  return (
    <section className={`relative mt-[70px] flex flex-col items-center lg:mt-[275px]`}>
      <img src={`/assets/images/wkm_walks_bg.svg`} alt={`WalkMining Explorer`} className={`w-full`} />
      <div className={`-mt-[60px] lg:absolute lg:bottom-[150px] lg:mt-0`}>
        <div className={`max-auto flex flex-col items-center justify-center space-y-4`}>
          <h2
            className={`w-[340px] text-center text-[22px] font-black leading-[27px] text-[#050C29] lg:w-[800px] lg:text-[30px] lg:leading-[72px] xl:text-[50px]`}
          >
            {description}
          </h2>
          <p
            className={`gradient-header text-center font-sans text-[45px] font-black leading-[45px] -tracking-[0.02em] lg:text-[100px] lg:leading-[150px] xl:text-[150px]`}
          >
            {totalStep}
          </p>
        </div>
      </div>
    </section>
  )
}
