import Meta from '@/components/Meta'
import About from '@/components/landing/About'
import Explorer from '@/components/landing/Explorer'
import Hero from '@/components/landing/Hero'
import ProductFeature from '@/components/landing/ProductFeature'
import ProductIntroduce from '@/components/landing/ProductIntroduce'
import Roadmap from '@/components/landing/Roadmap'
import TotalWalks from '@/components/landing/TotalWalks'
import Content from '@/components/layout/Content'
import Footer from '@/components/layout/Footer'
import Header from '@/components/layout/Header'
import Wrapper from '@/components/layout/Wrapper'
import { COMMUNITIES } from '@/constants/common'
import WalkMiningWebApi from '@/util/client/WalkMiningWebApiConnector'
import { CommunityLinkData } from '@/util/types/CommonType'
import { numberWithCommasSteps } from '@/util/utils'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'

export default function Index(props: any) {
  const { t } = useTranslation('translation')
  const { totalStepResponse } = props

  const playStoreUrl = `https://play.google.com/store/apps/details?id=com.walkmining.app.prod${
    props.locale ? `&hl=${props.locale}` : ''
  }`
  return (
    <>
      <Meta title={t('site_name') as string} desc={t('description') as string} keyword={t('keywords') as string} />
      <NextSeo
        title={t('site_name') as string}
        description={t('description') as string}
        openGraph={{
          title: t('site_name') as string,
          siteName: t('site_name') as string,
          description: t('description') as string,
          article: {
            authors: [t('app_name') as string],
          },
        }}
      />
      <Wrapper>
        <Header lng={props.locale} />
        <Content>
          <div className={`bg-[#FFF9E8]`}>
            <Hero appName={t('app_name')} heroTranslation={{ title: t('hero.title') }} playStoreUrl={playStoreUrl} />
            <About
              appName={t('app_name')}
              aboutTranslation={{
                title: t('about.title'),
                description: t('about.description'),
              }}
            />
            <div className={`feature-background rounded-t-[50px] 2xl:rounded-t-[100px] 2xl:bg-cover`}>
              <ProductIntroduce
                title={t('product_introduce.title')
                  .split('\n')
                  .map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                items={[
                  {
                    title: t('product_introduce.1.title'),
                    description: t('product_introduce.1.description'),
                  },
                  {
                    title: t('product_introduce.2.title'),
                    description: t('product_introduce.2.description'),
                  },
                  {
                    title: t('product_introduce.3.title'),
                    description: t('product_introduce.3.description'),
                  },
                ]}
              />
              <ProductFeature
                gameTitle={t('product_future.game.title')
                  .split('\n')
                  .map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                shopTitle={t('product_future.shop.title')
                  .split('\n')
                  .map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                gameItems={[
                  {
                    title: t('product_future.game.1.title')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                    description: t('product_future.game.1.description')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                  },
                  {
                    title: t('product_future.game.2.title')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                    description: t('product_future.game.2.description')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                  },
                  {
                    title: t('product_future.game.3.title')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                    description: t('product_future.game.3.description')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                  },
                ]}
                shopItems={[
                  {
                    title: t('product_future.shop.1.title')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                    description: t('product_future.shop.1.description')
                      .split('\n')
                      .map((line, idx) => <div key={idx}>{line}</div>),
                  },
                ]}
              />
            </div>
            <Explorer
              title={t('explorer.title')}
              btnExplorerText={t(`btn_view_explorer`)}
              description={t('explorer.description')
                .split('\n')
                .map((line, idx) => (
                  <div key={idx}>{line}</div>
                ))}
            />
            {totalStepResponse && (
              <TotalWalks
                description={<div>{t('walkminer_total_steps')}</div>}
                totalStep={
                  totalStepResponse && totalStepResponse.totalSteps > 0
                    ? numberWithCommasSteps(totalStepResponse.totalSteps)
                    : 0
                }
              />
            )}
            <Roadmap />
          </div>
        </Content>
        <Footer
          noticeTitle={t('about_notice_title')}
          aboutTitle={t('about_service_title')}
          terms={t('service_terms_of_service')}
          privacy={t('service_privacy_title')}
          communities={COMMUNITIES as CommunityLinkData[]}
        />
      </Wrapper>
    </>
  )
}

export async function getServerSideProps(ctx: any) {
  const isSSR = ctx.req !== null && ctx.req !== undefined
  const totalStepResponse = await WalkMiningWebApi.getTotalSteps(true)

  // 걸음 수 조회
  return {
    props: {
      isSSR,
      totalStepResponse,
      locale: ctx.locale,
      ...(await serverSideTranslations(ctx.locale, ['translation'])),
    },
  }
}
