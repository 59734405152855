export const COMMUNITIES = [
  {
    id: 'TWITTER',
    name: 'Twitter',
    url: 'https://twitter.com/walkmining',
    order: 0,
  },
  {
    id: 'MEDIUM',
    name: 'Medium',
    url: 'https://medium.com/@WalkMining',
    order: 1,
  },
  {
    id: 'TELEGRAM',
    name: 'Telegram',
    url: 'https://t.me/walkmining_official',
    order: 2,
  },
  {
    id: 'DISCORD',
    name: 'Discord',
    url: 'https://discord.com/invite/feuc2PR78g',
    order: 3,
  },
  {
    id: 'INSTAGRAM',
    name: 'Instagram',
    url: 'https://www.instagram.com/walkmining/',
    order: 4,
  },
  {
    id: 'GITBOOK',
    name: 'Document',
    url: 'https://docs.walkmining.com',
    order: 5,
  },
]
