function FeaturesDesktop({ title, items }: { title: any; items: any[] }) {
  return (
    <div className={`mt-[90px] flex flex-col space-y-[90px] lg:mt-[230px] lg:space-y-[230px]`}>
      <div className={`relative flex flex-col items-center justify-center`}>
        <h2
          className={`z-10 max-w-[240px] text-center text-[25px] font-black leading-[38px] lg:max-w-[515px] lg:text-[59px] lg:leading-[90px]`}
        >
          {title}
        </h2>
        <img
          src={`/assets/images/artwork_people.svg`}
          className={`z-10 mt-[60px] max-w-[230px] lg:mt-[148px] lg:max-w-[600px]`}
          alt={`walk_people`}
        />
        <div
          className={`button-frame relative z-0 -mt-[10px] max-w-[330px] rounded-[25px] p-[25px] transition-opacity lg:max-w-[695px] lg:rounded-[40px] lg:p-[60px]`}
        >
          <h3 className={`text-[18px] font-black lg:text-[35px]`}>{items[0].title}</h3>
          <p className={`text-[13px] font-bold leading-[20px] -tracking-[.03em] lg:text-[25px] lg:leading-[38px]`}>
            {' '}
            {items[0].description}
          </p>
        </div>
      </div>
      <div className={`relative flex flex-col items-center justify-center`}>
        <img
          src={`/assets/images/app_screen.png`}
          className={`z-0 mt-[60px] max-w-[230px] lg:mt-[148px] lg:max-w-[600px]`}
          alt={`app_screen`}
        />
        <div
          className={`button-frame relative z-10 -mt-[80px] max-w-[330px] rounded-[25px] p-[25px] transition-opacity lg:-mt-[180px] lg:max-w-[695px] lg:rounded-[40px] lg:p-[60px]`}
        >
          <h3 className={`text-[18px] font-black lg:text-[35px]`}>{items[1].title}</h3>
          <p className={`text-[13px] font-bold leading-[20px] -tracking-[.03em] lg:text-[25px] lg:leading-[38px]`}>
            {items[1].description}{' '}
          </p>
        </div>
      </div>
      <div className={`relative flex flex-col items-center justify-center`}>
        <img
          src={`/assets/images/artwork_shopping.svg`}
          className={`z-10 mt-[60px] max-w-[230px] lg:mt-[148px] lg:max-w-[600px]`}
          alt={`shopping`}
        />
        <div
          className={`button-frame relative z-0 -mt-[10px] max-w-[330px] rounded-[25px] p-[25px] transition-opacity lg:max-w-[695px] lg:rounded-[40px] lg:p-[60px]`}
        >
          <h3 className={`text-[18px] font-black lg:text-[35px]`}>{items[2].title}</h3>
          <p className={`text-[13px] font-bold leading-[20px] -tracking-[.03em] lg:text-[25px] lg:leading-[38px]`}>
            {items[2].description}{' '}
          </p>
        </div>
      </div>
    </div>
  )
}

export default function ProductIntroduce({ title, items }: { title: any; items: any[] }) {
  return (
    <section
      id={`productIntroduce`}
      className={`relative z-40 mx-auto flex max-w-[1320px] flex-col items-center justify-center rounded-t-[80px] text-[#050C29] lg:px-4`}
    >
      <div className={`block pb-[110px] lg:pb-[350px]`}>
        <FeaturesDesktop title={title} items={items} />
      </div>
    </section>
  )
}
