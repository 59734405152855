export default function Roadmap() {
  return (
    <section id={`roadmap`} className={`relative flex flex-col`}>
      {/*<div className={`relative z-20 hidden lg:block`}>*/}
      {/*  <img src={roadmapImage} alt={`roadmap`} className={`z-20 h-auto w-full`} />*/}
      {/*</div>*/}
      {/*<div className={`relative z-20 block lg:hidden`}>*/}
      {/*  <img src={roadmapImageMobile} alt={`roadmap`} className={`z-20 h-auto w-full`} />*/}
      {/*</div>*/}
      <div className={`relative z-10 hidden w-full sm:block`}>
        <img src={`/assets/images/footer-bg.svg`} alt={'footer'} className={`w-full`} />
      </div>
      <div className={`relative z-10 block w-full sm:hidden`}>
        <img src={`/assets/images/footer-bg-mobile.svg`} alt={'footer'} className={`w-full`} />
      </div>
    </section>
  )
}
