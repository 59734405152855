import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

const LANGUAGES = [
  {
    code: 'en',
    title: 'Global',
    icon: 'https://global-images.walkmining.com/country/icon/ic_global.png',
  },
  {
    code: 'ko',
    title: '한국어',
    icon: 'https://global-images.walkmining.com/country/icon/country_korea.png',
  },
  {
    code: 'ja',
    title: '日本語',
    icon: 'https://global-images.walkmining.com/country/icon/country_japan.png',
  },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function LanguageDropdown({ lng }: { lng: string }) {
  const lang = LANGUAGES.find((f: any) => f.code === lng) || LANGUAGES[0]
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md border-0 px-3 py-2 text-sm font-semibold text-gray-900">
          <img className="inline-block" src={lang.icon} width="25px" height="auto" alt={lang.title} />
          {lang.title}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white/30 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/en"
                  className={classNames(
                    active || lang.code === 'en' ? 'text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  )}
                >
                  <img
                    className="mr-1 inline-block"
                    src={LANGUAGES[0].icon}
                    width="20px"
                    height="auto"
                    alt={LANGUAGES[0].title}
                  />
                  Global
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/ko"
                  className={classNames(
                    active || lang.code === 'ko' ? ' text-gray-900' : 'text-gray-500',
                    'block px-4 py-2 text-sm',
                  )}
                >
                  <img
                    className="mr-1 inline-block"
                    src={LANGUAGES[1].icon}
                    width="20px"
                    height="auto"
                    alt={LANGUAGES[1].title}
                  />
                  한국어
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/ja"
                  className={classNames(
                    active || lang.code === 'ja' ? 'text-gray-900' : 'text-gray-500',
                    'block px-4 py-2 text-sm',
                  )}
                >
                  <img
                    className="mr-1 inline-block"
                    src={LANGUAGES[2].icon}
                    width="20px"
                    height="auto"
                    alt={LANGUAGES[2].title}
                  />
                  日本語
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
